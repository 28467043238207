import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex justify-center" 
    >
      <div className="max-w-screen-lg">
      <center>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">My schooling</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education Experience</h2>
        </div>
        </center>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="BTech | CSE"
            subTitle="2021 - 2025 | pursuing"
            result="9.35/10"
            des="Koneru Lakshmaiah Education Foundation, Guntur, Andhra Pradesh"
          />
          <ResumeCard
            title="INTERMEDIATE | MPC "
            subTitle="2019 - 2021 | Completed"
            board="Board : BIEAP"
            result="971/1000 Marks"
            des="Chakradhar Junior College, Srikakulam, Andhrapradesh"
          />
          <ResumeCard
            title="Secondary School Education | SSC"
            subTitle="2018 - 2019 | Completed"
            board="Board : BSEAP"
            result="9.3/10 CGPA"
            des="Sri Guru Narayana Vidyanikethan, Ragolu, Srikakulam, Andhrapradesh"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
