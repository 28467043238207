import React from 'react';
import { Modal, Button } from 'antd';

const PdfViewerModal = ({ pdfUrl, visible, onCancel }) => {
  const iframeStyle = {
    width: '100%',
    height: '80vh',
    objectFit: 'cover',
    border: 'none'
  };

  return (
    <Modal
      title="My Resume"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
      style={{ height: 100, top: 2 }}
    >
      <iframe title="My Resume" src={pdfUrl} style={iframeStyle} />
      <div style={{ textAlign: 'center', padding: '10px 0' }}>
        <Button onClick={onCancel} style={{ marginRight: '10px' }}>Close</Button>
        <a href={"https://drive.google.com/uc?export=download&id=1fxjHdBsrr9Cvf2vzHBQ-33Lv1p3y4a8o"} download="MyResume.pdf">
          <Button style={{ color: 'white', backgroundColor: '#f00' }} type="primary">Download</Button>
        </a>
      </div>
    </Modal>
  );
};

export default PdfViewerModal;
