import React, { useState } from 'react';

const Card = ({ title, des, icon }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="w-30 h-30 px-8 py-6 rounded-lg shadow-shadowOne flex items-center bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-black hover:to-[#1e2024] transition-colors duration-100 group"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="overflow-hidden">
        <div className="flex flex-col gap-4 justify-between h-full group-hover:transform group-hover:scale-80 transition-transform duration-300">
          <div className="w-10 h-20 flex items-center justify-center">
            {icon && (
              <span className="text-4xl text-designColor">{icon}</span>
            )}
          </div>

          <div className="flex flex-col gap-2">
            {hovered && (
              <h2 className="text-lg md:text-xl font-titleFont font-bold text-gray-300 justify-center">
              {title}
              </h2>
            )}
            <span className="text-base text-designColor"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
