import React, { useState } from 'react';
import Title from '../layouts/Title';
import { projectOne, projectTwo, projectThree, expense, tp,te,todolist } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  const [status, setStatus] = useState(false);

  const HandleShowMore = () => {
    setStatus(!status);
  };

  const projects = [
    {
      title: "TodoList",
      des: "A todolist Application made using react, node, mongodb.It is very useful while we have so many taks to do!",
      src: todolist,
      githubLink: "https://github.com/Venkataramanabaratam1/ToDofrontend",
      deploymentlink: "https://todolist.venkataramanabaratam.live/",
    },
    {
      title: "Health and Life Insurance System",
      des: "A Python Full Stack Health and Life Insurance System using HTML, CSS, JS, Django, PostgreSQL, and Python libraries for web development and database management!",
      src: projectTwo,
      githubLink: "https://github.com/Venkataramanabaratam1/PYTHON-SDP",
      deploymentlink: "https://venky2852.pythonanywhere.com/",
    },
    {
      title: "Expense/Money-Tracker",
      des: "An expense or money tracker helps monitor and manage finances by recording and categorizing income and expenses, displaying transactions in barchart and providing edit & delete options to every transaction and finally we can download total transactions in a pdf format or csv format.",
      src: expense,
      githubLink: "https://github.com/Venkataramanabaratam1/Expense-Money-Tracker",
      deploymentlink: "https://expensetracker.venkataramanabaratam.live/",
    },
    {
      title: "Event Management System",
      des: "Effortlessly plan and manage events with our MERN Stack system. Utilizing React JS, CSS, Node JS, Express, and MongoDB, it ensures seamless organization, user-friendly interfaces, and efficient backend operations for streamlined event planning!",
      src: projectOne,
      githubLink: "https://github.com/Venkataramanabaratam1/MERNSTACK-SDP",
      deploymentlink: "",
    },
    {
      title: "Student Course Management System",
      des: "A Java Full Stack Student Course Management System using HTML, CSS, JS, Java, Spring Boot, MySQL, and Rest API for web development, database management, and seamless course administration.",
      src: projectThree,
      githubLink: "https://github.com/Venkataramanabaratam1/JFSD-SDP",
      deploymentlink: "",
    },
    {
      title: "Training & Placement Management",
      des: "The Training & Placement Management System deals with the Trainings and placements of the registered students.",
      src: tp,
      githubLink: "https://github.com/Venkataramanabaratam1/EP-REDHAT-SDP",
      deploymentlink: "",
    },
    {title:"Text Extractor using django",
      des:"By using this webapplication you can extract text from the desired images by uploading them.",
      src:te,
      githubLink:"https://github.com/Venkataramanabaratam1/TextExtractor",
      deploymentlink:"https://textextraction.pythonanywhere.com/",
    }
  ];

  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PROJECTS AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        {projects.slice(0, status ? projects.length : 3).map((project, index) => (
          <ProjectsCard
            key={index}
            title={project.title}
            des={project.des}
            src={project.src}
            githubLink={project.githubLink}
            deploymentlink={project.deploymentlink}
          />
        ))}
      </div>

      <div className="flex justify-center mt-8">
        <button
          onClick={HandleShowMore}
          className="flex items-center px-6 py-3 text-white rounded-lg hover:bg-blue-700"
          style={{ backgroundColor: '#ff4500' }}
        >
          {status ? (
            <>
              Show Less
              <svg
                className="ml-2 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </>
          ) : (
            <>
              Show More
              <svg
                className="ml-2 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </>
          )}
        </button>
      </div>
    </section>
  );
}

export default Projects;
