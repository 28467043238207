import React from 'react'
import { FaInstagram,FaWhatsapp,FaTelegram,FaGithub,FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-36 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Venkataramana Baratam</h3>
        <p className="text-lg font-normal text-gray-400">
          Full Stack Developer || Data Analyst
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+91 6304345373</span>
        </p>
        <p className="text-base text-gray-400">
          <button className="text-lightText">Email 1: 2100032420cseh@gmail.com</button>
          <br />
          <button className="text-lightText">Email 2: bvenkataramana2852@gmail.com</button>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Contact me on</h2>
        <div className="flex gap-4">
           <a href="https://www.linkedin.com/in/venkataramanabaratam" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaLinkedinIn />
            </span>
            </a>
            <a href="https://github.com/Venkataramanabaratam1" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaGithub />
            </span>
            </a>
            <a href="https://www.instagram.com/its_me_yours_venky/" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaInstagram />
            </span>
            </a>
            <a href="https://wa.me/+916304345373" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaWhatsapp />
            </span>
            </a>
            <a href="https://t.me/venkataramanabaratam" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaTelegram />
            </span>
            </a>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft