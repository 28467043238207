export const navLinksdata = [
  {
    _id: 1001,
    title: "Home",
    link: "home",
  },
  {
    _id: 1002,
    title: "About Me",
    link: "Aboutme",
  },
  {
    _id: 1004,
    title: "Skills",
    link: "skills",
  },
  {
    _id: 1005,
    title: "Projects",
    link: "projects",
  },
  {
    _id: 1006,
    title: "Experience",
    link: "resume",
  },
  {
    _id: 1007,
    title: "Certifications",
    link: "certifications",
  },
  
];