import React, { useState } from 'react';
import Title from '../layouts/Title';
import { cp, da,bec,redhat,ai,az, sn, adc, mc,oc, rpa } from "../../assets/index";
import Certificationcard from './Certificationcard';
// import { Fa } from "react-icons/fa";

const Certificates = () => {
  const [status, setStatus] = useState(false);
  const [filter, setFilter] = useState("All");

  const HandleShowMore = () => {
    if (status) {
      setFilter("All");
    }
    setStatus(!status);
  };

  const certificates = [
    {
      title: "Cloud Practitioner",
      des: "Amazon web Services(AWS)",
      src: cp ,
      verificationLink: "https://www.credly.com/badges/4dae3cf5-2525-4f2f-b764-da216210bcf4/public_url" ,
      tags: ["AWS"]
    },
    {
      title: "Developer Associate",
      des: "Amazon web Services(AWS)",
      src: da,
      verificationLink: "https://kluniversityin-my.sharepoint.com/:b:/g/personal/2100032420_kluniversity_in/EdGjA7MWNfFOn3MGpz9Eem0BGlGxPU4bpxjpUUyQhFWpGA?e=yTu2G8",
      tags: ["AWS"]
    },
    {
      title: "Red hat - EX183",
      des: "RedHat",
      src: redhat,
      verificationLink: "https://www.credly.com/badges/cbf0dc99-ed02-458b-bf7c-1448ff2a9767/public_url",
      tags: ["Others"]
    },
    {
      title: "Bussiness English Certificate",
      des: "Cambridge",
      src: bec,
      verificationLink: "https://kluniversityin-my.sharepoint.com/:b:/g/personal/2100032420_kluniversity_in/EQqsCYD6a_dLrG4MdiV-3rQBuQIrpgJlhHUC6OmDLl3YHw?e=gCwfw5",
      tags: ["Others"]
    },
    {
      title: "AZ-900",
      des: "Microsoft",
      src: az,
      verificationLink: "https://www.credly.com/badges/c24cc95a-f04d-4d2e-a3fc-2993cd68285b/public_url",
      tags: ["Microsoft"]
    },
    {
      title: "AI-900",
      des: "Microsoft",
      src: ai,
      verificationLink: "https://www.credly.com/badges/6ec58963-d33b-492c-b183-befbe91a0e30/public_url",
      tags: ["Microsoft"]
    },
    {
      title: "System Administrator",
      des: "Service Now",
      src: sn,
      verificationLink: "https://kluniversityin-my.sharepoint.com/:b:/g/personal/2100032420_kluniversity_in/EerRTOGrrT1NuR7QQK0ZvY4B8erTqCQ1duY6oO2FPxCacw?e=YAucf0",
      tags: ["Others"]
    },
    {
      title: "Designer Core",
      des: "Alteryx",
      src: adc,
      verificationLink: "https://www.credly.com/badges/f2273f8e-70b1-4411-b459-3eb5120ed4db/public_url",
      tags: ["Alteryx"]
    },
    {
      title: "Micro Credential",
      des: "Alteryx",
      src: mc,
      verificationLink: "https://www.credly.com/badges/cbe5e9ee-851c-4388-9dbb-34eb847e3ad0/public_url",
      tags: ["Alteryx"]
    },
    {
      title: "Oracle Associate",
      des: "Oracle",
      src: oc,
      verificationLink: "https://kluniversityin-my.sharepoint.com/:b:/g/personal/2100032420_kluniversity_in/EU3GpWrf4Y1Jt1sHGEcK9IwBDQDuSs2-PtHapVE-9shzsA?e=uJycOC",
      tags: ["Others"]
    },
    {
      title: "RPA Professional",
      des: "Automation Anywhere",
      src: rpa,
      verificationLink: "https://certificates.automationanywhere.com/profile/venkataramanabaratam970292/wallet#main-content",
      tags: ["Others"]
    }

  ];

  const filteredCertificates = filter === "All" ? certificates : certificates.filter(cert => cert.tags.includes(filter));

  return (
    <section id="certifications" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center mb-8">
        <Title title="Global Certifications" des="My Certifications" />
      </div>

      {status && (
        <div className="flex justify-center flex-wrap space-x-4 mb-8 px-4">
          {["All", "AWS", "Microsoft", "Google","WordPress", "HTML","Alteryx","Others"].map((item) => (
            <button
              key={item}
              onClick={() => setFilter(item)}
              className={`px-4 py-2 mb-2 rounded-lg ${filter === item ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black hover:bg-gray-300'}`}
            >
              {item}
            </button>
          ))}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14 px-4">
        {filteredCertificates.slice(0, status ? filteredCertificates.length : 3).map((cert, index) => (
          <Certificationcard
            key={index}
            title={cert.title}
            des={cert.des}
            src={cert.src}
            verificationLink={cert.verificationLink}
          />
        ))}
      </div>

      <div className="flex justify-center mt-8 px-4">
        <button
          onClick={HandleShowMore}
          className="flex items-center px-6 py-3 text-white rounded-lg hover:bg-blue-700"
          style={{ backgroundColor: '#ff4500' }}
        >
          {status ? (
            <>
              Show Less
              <svg
                className="ml-2 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </>
          ) : (
            <>
              Show More
              <svg
                className="ml-2 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </>
          )}
        </button>
      </div>
    </section>
  );
}

export default Certificates;
