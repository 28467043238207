import React from 'react'
import { FaWordpress, FaHtml5, FaReact, FaCss3, FaJava, FaAws, FaRedhat, FaNodeJs, FaFileExcel, FaGithub} from "react-icons/fa";
import { SiMongodb, SiExpress, SiMysql, SiPostgresql,SiSpringboot, SiDjango, SiPostman, SiTableau } from "react-icons/si";
import Title from '../layouts/Title';
import Card from './Card';

const Skills = () => {
  return (
    <section
      id="skills"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title title="Skills" des="That I Have" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="React"
          icon={<FaReact/>}
        />
        <Card
          title="Html"
          icon={<FaHtml5/>}
        />
        <Card
          title="CSS"
          icon={<FaCss3/>}
        />
        <Card
          title="Wordpress"
          icon={<FaWordpress/>}
        />
        <Card
          title="Java"
          icon={<FaJava/>}
        />
        <Card
          title="Mongodb"
          icon={<SiMongodb/>}
        />
        <Card
          title="AWS"
          icon={<FaAws/>}
        />
        <Card
          title="Redhat - EX183"
          icon={<FaRedhat/>}
        />
        <Card
          title="Express-Js"
          icon={<SiExpress/>}
        />
        <Card
          title="SQL"
          icon={<SiMysql/>}
        />
        <Card
          title="Postgresql"
          icon={<SiPostgresql/>}
        />
        <Card
          title="Node Js"
          icon={<FaNodeJs/>}
        />
       <Card
          title="Spring Boot"
          icon={<SiSpringboot/>}
        />
        <Card
          title="Django"
          icon={<SiDjango/>}
        />
        <Card
          title="Excel"
          icon={<FaFileExcel/>}
        />
        <Card
          title="Github"
          icon={<FaGithub/>}
        />
        <Card
          title="Postman"
          icon={<SiPostman/>}
        />
        <Card
          title="Tableau"
          icon={<SiTableau/>}
        />
      </div>
    </section>
  );
}

export default Skills