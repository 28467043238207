import React,{useState} from 'react'
import Title from '../layouts/Title';
import ContactLeft from './ContactLeft';
import emailjs from '@emailjs/browser';
import "../about/about.css"
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

  const EmailForm = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phoneno, setphoneno] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_88mx7ac';
    const templateId = 'template_0nrf8hw';
    const publicKey = '24xSQJ3Opmu3yoUap';
   
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Venkataramana',
      message: message,
      from_phoneno:phoneno,
    };
    message.length>0 && name&& email && phoneno?
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        toast.success(
          'Message sent succesfully!',{
            position:'top-right',
            duration:2000
          }
        )
        console.log('Email sent successfully!', response);
        setName('');
        setEmail('');
        setMessage('');
        setphoneno('');
      })

      .catch((error) => {
        console.error('Error sending email:', error);
      }): toast.error('Please Enter all the fileds!',{
        position:'top-right',
        duration:2000
      })
  }

  return (
    <section
      id="contact"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="CONTACT" des="Connect With Me" />
      </div>
      <div className="w-full">
        <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
          <ContactLeft />
          <div className="w-full lgl:w-[60%] h-full py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] flex flex-col gap-8 p-4 lgl:p-8 rounded-lg shadow-shadowOne">
          <form className="w-full flex flex-col gap-4 lgl:gap-6 py-2 lgl:py-5">
            <div className="w-full flex flex-col lgl:flex-row gap-10">
              <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                <p className="text-sm text-gray-400 uppercase tracking-wide">Your name</p>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                  placeholder='Enter your name!'
                  type="text"
                  className="w-full p-3 bg-[#1e2024] border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                <p className="text-sm text-gray-400 uppercase tracking-wide">Mobile No</p>
                <input
                  onChange={(e) => setphoneno(e.target.value)}
                  value={phoneno}
                  required
                  placeholder='Enter your number!'
                  type="number"
                  className="w-full p-3 bg-[#1e2024] border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <p className="text-sm text-gray-400 uppercase tracking-wide">Email</p>
              <input
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Enter your email!'
                required
                value={email}
                type="email"
               className="w-full p-3 bg-[#1e2024] border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-blue-500"
              />
           </div>
           <div className="flex flex-col gap-4">
             <p className="text-sm text-gray-400 uppercase tracking-wide">Message</p>
             <textarea
               onChange={(e) => setMessage(e.target.value)}
               placeholder='Enter your Message!'
               required
               value={message}
               cols="30"
               rows="8"
               className="w-full p-3 bg-[#1e2024] border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-blue-500 resize-none"
            ></textarea>
           </div>
           <div className="w-full">
           <button
            onClick={handleSubmit}
            className="w-full h-12 bg-[#141518] rounded-lg text-base text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-designColor border-transparent"
           >
           Send Message
          </button>
          </div>
       </form>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </section>
  );
}

export default EmailForm