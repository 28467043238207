import React from 'react'
import {FaInstagram,FaTelegram, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import {logo} from "../../assets/index"


const Footer = () => {
  return (
    
    <div className="w-full py-20 h-auto border-b-[1px] border-b-black grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 gap-8">
      
      <div className="w-full h-full flex flex-col gap-8">
      
        <img className="w-32" src={logo} alt="logo" />

        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
            VENKATARAMANA BARATAM
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 ">
            Student at KL University
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
            Vijayawada, Andhrapradesh.
            </span>
          </li>
         </ul> 

        <div className="flex gap-4">
           <a href="https://www.linkedin.com/in/venkataramanabaratam" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaLinkedinIn />
            </span>
            </a>
            <a href="https://www.instagram.com/its_me_yours_venky/" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaInstagram />
            </span>
            </a>
            <a href="https://t.me/venkataramanabaratam" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaTelegram />
            </span>
            </a>
            <a href="https://wa.me/+916304345373" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaWhatsapp />
            </span>
            </a>
        </div>
      </div>
      
      <div className="w-full h-full">
        <h3 className="text-xl uppercase text-designColor tracking-wider">
          Quick Links
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              About
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              Portfolio
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              Services
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              Blog
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              Contact
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
        </ul>
      </div>
      

      <div className="w-full h-full md:col-span-1 lgl:col-span-1">
        <h3 className="text-xl uppercase text-designColor tracking-wider">
          Contact Info
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              Phone Number : +916304345373
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 ">
              Email : 2100032420cseh@gmail.com 
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              Location : Andhrapradesh, India, 522502.
            </span>
          </li>
         </ul> 
      </div>
      <center><h2>Thanks for exploring my personal portfolio website! Let's stay connected through social media.</h2></center>
    </div>
  );
}

export default Footer