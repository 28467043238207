import React, { useEffect } from "react";
import Banner from "./components/banner/Banner";
import Contact from "./components/contact/Contact";
import Features from "./components/features/Features";
import Footer from "./components/footer/Footer";
import FooterBottom from "./components/footer/FooterBottom";
import Navbar from "./components/navbar/Navbar";
import Projects from "./components/projects/Projects";
import Resume from "./components/resume/Resume";
import AboutMePage from "./components/about/about";
import Certificates from "./components/Certification/Certification";

function App() {
  useEffect(() => {
    const handleContextMenu = (event) => {
      alert("Inspect Option disabled on this site");
      event.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div className="w-full h-auto bg-bodyColor text-lightText px-4">
        <Navbar />
      <div className="max-w-screen-xl mx-auto">
        <Banner />
        <AboutMePage/>
        <Features />
        <Projects />
        <Resume />
        <Certificates/>
        <Contact />
        <Footer />
        <FooterBottom />
      </div>

    </div>
  );
}

export default App;





