import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { FiMenu } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { FaInstagram, FaTelegram, FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { logo } from '../../assets/index';
import { navLinksdata } from '../../constants';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="w-full h-24 sticky top-0 z-50 bg-bodyColor mx-auto flex justify-between items-center font-titleFont border-b-[1px] border-b-gray-600">
      <div>
      <Link to='home' activeClass="active" spy={true} smooth={true} offset={-70} duration={500}>
        <img src={logo} alt="logo" style={{ width: '90px', height: '80px' }} />
      </Link>
      </div>
      <div className='flex items-center'>
        <ul className="hidden mdl:inline-flex items-center gap-6 lg:gap-10">
          {navLinksdata.map(({ _id, title, link }) => (
            <li className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300" key={_id}>
              <Link activeClass="active" to={link} spy={true} smooth={true} offset={-70} duration={500}>
                {title}
              </Link>
            </li>
          ))}
        </ul>
        
        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-xl mdl:hidden bg-black w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor cursor-pointer"
          style={{marginTop:2}}
        >
          <FiMenu />
        </span>
        <ButtonToNewPage style={{ marginRight: '10px' }} />
        {showMenu && (
          <div className="w-[80%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-900 p-4 scrollbar-hide">
            <div className="flex flex-col gap-8 py-2 relative">
              <div>
                <img className="w-32" src={logo} alt="logo" />
                <p className="text-sm text-gray-400 mt-2">
                 Final year Computer Science student at KL University, specializing in Data Science,
                 passionate about Software Development, skilled in DSA, Full Stack Development, 
                 Machine Learning, and Cloud Computing.
                </p>
              </div>
              <ul className="flex flex-col gap-4">
                {navLinksdata.map((item) => (
                  <li
                    key={item._id}
                    className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
                  >
                    <Link
                      onClick={() => setShowMenu(false)}
                      activeClass="active"
                      to={item.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="flex flex-col gap-4">
                <h2 className="text-base uppercase font-titleFont mb-4">
                  Find me in
                </h2>
                <div className="flex gap-4">
                  <a href="https://www.linkedin.com/in/venkataramanabaratam" target="_blank" rel="noopener noreferrer">
                    <span className="bannerIcon">
                      <FaLinkedinIn />
                    </span>
                  </a>
                  <a href="https://github.com/Venkataramanabaratam1" target="_blank" rel="noopener noreferrer">
                    <span className="bannerIcon">
                      <FaGithub />
                    </span>
                  </a>
                  <a href="https://www.instagram.com/its_me_yours_venky/" target="_blank" rel="noopener noreferrer">
                    <span className="bannerIcon">
                      <FaInstagram />
                    </span>
                  </a>
                  <a href="https://t.me/venkataramanabaratam" target="_blank" rel="noopener noreferrer">
                    <span className="bannerIcon">
                      <FaTelegram />
                    </span>
                  </a>
                </div>
              </div>
              <span
                onClick={() => setShowMenu(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-designColor duration-300 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ButtonToNewPage = ({ style }) => {
 
  return (
    <> &nbsp;&nbsp;
    <Link to='contact' activeClass="active" spy={true} smooth={true} offset={-70} duration={500}>
    <button className="custom-button3" style={style}>
     Hire me
    </button>
    </Link>
    </>
  );
};

export default Navbar;
