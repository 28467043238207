import React, { useState } from "react";
import empty from "../../assets/empty.jpeg";
import './about.css';
import Title from "../layouts/Title";
import PdfViewerModal from './PdfViewerModal';  
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AboutMePage = () => {
  const [pdfModalVisible, setPdfModalVisible] = useState(false);

  const openPdfModal = () => {
    setPdfModalVisible(true);
    // toast.success(
    //   'Resume displayed successfully!', {
    //     position: 'top-right',
    //     duration: 2000
    //   });
  };

  const closePdfModal = () => {
    setPdfModalVisible(false);
  };

  return (
    <section
      id="Aboutme"
      className="w-full py-7 border-b-[1px] border-b-black"
    >
      <Title title="About" des="Myself" />

      <div className="container mx-auto px-4 py-0 flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 mb-4 md:mb-0">
          <img
            src={empty}
            alt="venky"
            className="rounded-full w-96 h-96 object-cover mx-auto md:mx-0"
          />
        </div>

        <div className="md:w-1/2 md:pl-8">
          <h1 className="text-3xl font-bold mb-4" style={{ color: 'White', fontSize: '24px' }}>About Me</h1>
          <p className="text-lg leading-relaxed">
            I am a final year student pursuing Computer Science and Engineering at KL University,
            Vijayawada. Passionate about Data Science and Software Development. Skilled in DSA, Full Stack, Data Science,
            ML, and Cloud. Enthusiastic about problem-solving, research, and software design. I am open to learning new things
            and eager to leverage my expertise and experience in software development.
          </p>
          <br/>
          <div className="text-lg leading-relaxed">
            <h4>Phone No : 6304345373</h4>
            <h4>Email : 2100032420cseh@gmail.com</h4>
            <h4>Address : Gudem, Srikakulam, Andhrapradesh - 532484.</h4>
          </div>
          <br/>
    
            <div className="custom-button">
              <button onClick={openPdfModal}>My Resume</button>
            </div>

        </div>
      </div>
      <PdfViewerModal
        pdfUrl="https://drive.google.com/file/d/1fxjHdBsrr9Cvf2vzHBQ-33Lv1p3y4a8o/preview" 
        visible={pdfModalVisible}
        onCancel={closePdfModal}
      />
      {/* <ToastContainer /> */}
    </section>
  );
};

export default AboutMePage;
