import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaGithub,FaInstagram,FaLinkedinIn,FaTelegram,FaWhatsapp } from "react-icons/fa";
// import { SiTailwindcss, SiFigma, SiNextdotjs,FaReact,FaHtml5,FaJava,FaDatabase } from "react-icons/si";
import '../about/about.css'
const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Professional Coder.", "Full Stack Developer.", "UI Designer.","Data Enthusiast."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize "  id='res'>Venkataramana</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        Welcome to my personal portfolio!, Here you'll find a detailed overview of my skills, 
        qualifications, and experience in the field of computer science.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
           <a href="https://www.linkedin.com/in/venkataramanabaratam" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaLinkedinIn />
            </span>
            </a>
            <a href="https://github.com/Venkataramanabaratam1" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaGithub />
            </span>
            </a>
            <a href="https://www.instagram.com/its_me_yours_venky/" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaInstagram />
            </span>
            </a>
            <a href="https://wa.me/+916304345373" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaWhatsapp />
            </span>
            </a>
            <a href="https://t.me/venkataramanabaratam" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaTelegram />
            </span>
            </a>
        </div>
        </div>
        {/* <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <FaReact />
            </span>
            <span className="bannerIcon">
              <FaHtml5 />
            </span>
            <span className="bannerIcon">
              <FaJava />
            </span>
            <span className="bannerIcon">
              <FaDatabase />
            </span>
            <span className="bannerIcon">
              <SiNextdotjs />
            </span>
            <span className="bannerIcon">
              <SiTailwindcss />
            </span>
            <span className="bannerIcon">
              <SiFigma />
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default LeftBanner
