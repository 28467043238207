import React, { useState } from 'react';

  const ProjectsCard = ({ title, des, src, verificationLink }) => {
  const [isHovered, setIsHovered] = useState(false);


  return (
    <div 
      className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-full h-[100%] overflow-hidden rounded-lg">
        <img
          className="w-full h-50 object-cover group-hover:scale-110 duration-300 cursor-pointer"
          src={src}
          alt={title}
        />
      </div>
      <div className="w-full mt-5 flex flex-col">
        <div>
          <div className="flex items-center w-100  justify-between ">
            <div >
              
            <h3 className="text-base uppercase text-designColor  bg-gray-800 text-white px-4 py-2 rounded-lg">
              {title}
            </h3>
            </div>
            {isHovered && (
              <div>
                <a href={verificationLink} target="_blank" rel="noopener noreferrer">
                  <button 
                   className="text-lg w-20 h-10 rounded-md bg-black text-gray-400 hover:text-designColor duration-300 cursor-pointer outline-none focus:outline-none"
                  
                  >
                  Verify
                </button>
              </a>
              </div>
            )}
          </div>
          {isHovered && (
            <p className="text-sm tracking-wide mt-3 font-bold hover:text-gray-100 duration-300">
              Issued by : {des}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectsCard;
